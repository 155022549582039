import { Component, Inject, OnDestroy } from '@angular/core';
import { DeviceService, GlobalConfig } from '@pv-frontend/gift-voucher-journey';
import {
  JOURNEY_METADATA_SERVICE_TOKEN,
  JourneyMetadataService,
} from '@pv-frontend/pv-shared-services/journey-metadata-service';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'hdfc-si-benefits-explainer',
  templateUrl: './benefits-explainer.component.html',
  styleUrls: ['./benefits-explainer.component.scss'],
})
export class BenefitsExplainerComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  public isUserLoggedIn: boolean = false;
  public imagePlaceholder: string = '';
  public benefits: { title: string; imgSrc?: string }[] = [
    {
      title:
        'Select brand of your choice from Amazon, Flipkart, Swiggy and Book My Show',
    },
  ];
  public postLoginCTA = [
    {
      title:
        'Select brand of your choice from <span class="text-yellow-400 font-bold" >Amazon, Flipkart, Swiggy</span> and <span class="text-yellow-400 font-bold" >Book My Show</span>.',
    },
    {
      title:
        'Subscribe for 12 Months and save 50% each month <br>Or<br> Get an instant discount of 30% for your One Time purchase',
    },
  ];
  public constructor(
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,
    @Inject(JOURNEY_METADATA_SERVICE_TOKEN)
    public journeyMetadataService: JourneyMetadataService,
    public deviceService: DeviceService
  ) {
    this.pvUserService.loginModalObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((showAuthenticationModal: boolean) => {
        console.log(
          '~ BenefitsExplainerComponent ~ .subscribe ~ showAuthenticationModal:',
          showAuthenticationModal
        );
      });
    this.pvUserService.userLoggedInObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.benefits = this.postLoginCTA;
        }
      });

    if (this.pvUserService.isUserAuthenticated()) {
      this.benefits = this.postLoginCTA;
      this.isUserLoggedIn = true;
    }
    this.imagePlaceholder =
      (
        this.journeyMetadataService.getJourneyConfig(
          'journey.global'
        ) as GlobalConfig
      ).config?.imagePlaceholder ?? '';
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
