import { GiftVouchersConfig } from '@pv-frontend/gift-voucher-journey';

export const giftVouchersConfig: GiftVouchersConfig = {
  global: {
    config: {
      imagePlaceholder:
        'https://images.poshvine.com/icon/gv/rectangular-image-placeholder.jpeg',
      isCardBasedLogin: false,
      includeLoginFlow: false,
      pointsIconFirst: true,
      coinIconName: 'fi-coin',
      defaultPaymentMode: 'card_payment',
      allowedPaymentMethods: ['card_payment'],
      redemptionValueRoundingMethod: 'ceil',
    },
    style: {
      chevronDown: {
        width: '1.4375rem',
        height: '1.4375rem',
        fill: 'var(--brand-primary-500)',
      },
    },
  },

  voucherCard: {
    container: {
      style: {
        success: {
          background: '#008659',
        },
        error: {
          borderRadius: '12px',
          backgroundColor: 'var(--error-background-low-contrast)',
          border: '1px solid var(--error-red)',
          fill: 'var(--error-red)',
          color: 'var(--text-color)',
        },
      },
    },
    redeemButton: {
      style: {
        padding: '0',
        'text-decoration': 'underline',
        'font-size': '0.857rem',
        color: 'white',
        'min-width': 'auto',
      },
    },
    statusIcon: {
      style: {
        width: '1.14286rem',
        height: '1.14286rem',
      },
    },
    divider: {
      style: {
        width: 'calc(100% + 2rem)',
        margin: '1.4rem -1rem 1.9rem',
      },
    },
  },
  landingPage: {
    topBanner: {
      image: {
        borderRadius: '20px',
      },
    },
    middleBanner: {
      container: {
        background: 'var(--supporting-indigo-900)',
      },
      image: {
        borderRadius: '20px',
      },
    },
    viewAllButton: {
      style: {
        color: 'var(--interactive-light-green-text)',
        backgroundColor: 'var(--neutrals-ivory-50)',
        borderRadius: '20px',
        fontWeight: '600',
        width: 'calc(100vw - 32px)',
      },
    },
    offersSectionStyle: {
      style: {
        cardSection: {},
        categoryBasedOffers: {
          card: {},
        },
      },
    },
    showLoginAlert: true,
    sectionOrder: [
      'BANNERS',
      'SEARCH_BAR',
      'CATEGORIES',
      'PERSONALIZED_OFFERS',
      'LIMITED_TIME_OFFERS',
      'MID_BANNERS',
      'BEST_SELLER_OFFERS',
      'MOST_SEARCHED_OFFERS',
      'TOP_CATEGORY_BASED_OFFERS',
      'TOP_MEMBERSHIPS',
    ],
    personalisedCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          container: {
            color: 'var(--content-on-light-high-emphasis-850)',
          },
          image: {
            fill: 'var(--green-600)',
            'margin-right': '.2rem',
            height: '1.5rem',
          },
        },
        link: {},
        card: {
          'background-color': 'var(--background-light-base)',
          margin: '1.5rem 0 0',
          padding: '0 0.5rem 1.5rem',
          'border-radius': '20px',
        },
      },
    },
    personalisedCardStyle: {
      cardContainer: {
        width: 'calc(50dvw - 40px) !important',
        height: '240px !important',
        minWidth: '170px !important',
        minHeight: '239.501px !important',
      },
      information: { baseText: { color: 'green' } },
      image: {
        maxHeight: '107px',
      },
    },
    limitedTimeCardSectionStyle: {
      style: {
        backgroundImage: {},
        title: {
          container: {
            color: 'var(--content-on-light-high-emphasis-850)',
          },
          image: {
            'margin-right': '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          'font-weight': '600',
          'font-size': '0.75rem',
          color: 'var(--interactive-light-tertiary-action-2)',
          'text-transform': 'uppercase',
        },
        card: {
          'background-color': 'var(--background-light-base)',
          margin: '0',
          padding: '.3rem 0 1.5rem .5rem',
        },
      },
    },
    limitedTimeCardStyle: {
      style: {
        cardContainer: {
          'min-height': '15rem',
          'border-radius': '20px',
        },
        title: {},
        actionButton: {
          'font-weight': '600',
          color: 'var(--interactive-light-green-text)',
        },
      },
    },
    bestSellerCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          container: {
            'padding-left': '0.8rem',
            color: 'var(--content-on-light-high-emphasis-850)',
          },
          image: {
            fill: 'var(--green-600)',
            'margin-right': '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          'font-weight': '600',
          'font-size': '0.75rem',
          color: 'var(--interactive-light-tertiary-action-2)',
          'text-transform': 'uppercase',
        },
        card: {
          'background-color': 'var(--background-light-base)',
          margin: '0',
          padding: '.6rem 0rem 1.5rem',
        },
      },
    },
    bestSellerCardStyle: {
      style: {
        cardContainer: {
          width: '100% !important',
          height: '100% !important',
          border: '0',
        },
        title: {
          color: 'var(--surface-text-normal-low-contrast)',
        },
        cardContent: {
          padding: '0.75rem 0.75rem 2rem',
        },
        footer: {
          background:
            'linear-gradient(90deg, rgba(0, 140, 177, 0.12) 13.92%, rgba(0, 140, 177, 0.00) 111.39%)',
          position: 'absolute',
          width: '100%',
          bottom: '0',
          'font-size': '.75rem !important',
        },
      },
    },
    categoryOfferSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          container: {
            color: 'var(--content-on-light-high-emphasis-850)',
          },
          image: {
            fill: 'var(--green-600)',
            'margin-right': '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          'font-weight': '700',
          'font-size': '1rem',
        },
        card: {
          backgroundColor: 'var(--background-light-base)',
          margin: '0',
          padding: '.5rem 0.5rem .5rem',
        },
      },
    },
    categoryOfferCard: {
      style: {
        cardContainer: {
          width: '100% !important',
          height: '100% !important',
          border: '0',
          'border-radius': '16px !important',
        },
        cardContent: {
          'border-top': '0',
        },
        information: {
          attentionText: {
            color: 'var(--supporting-moss-700)',
          },
        },
        image: {
          minHeight: '107px',
          'border-radius': '16px 16px 0 0 !important',
        },
      },
    },
    topMembershipSectionStyle: {
      style: {
        backgroundImage: {
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          container: {
            color: 'var(--content-on-light-high-emphasis-850)',
          },
          image: {
            fill: 'var(--blue-600)',
            'margin-right': '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          color: 'var(--interactive-light-tertiary-action-2)',
          'text-transform': 'uppercase',
        },
        card: {
          'background-color': 'var(--background-dark-translucent)',
        },
      },
      config: {
        buttonLabel: 'View all',
        emphasizedTitleText: 'Top Memberships',
      },
    },
    topMembershipCardStyle: {
      style: {
        cardContainer: {
          width: '100% !important',
          height: '100% !important',
          border: '0',
          'border-radius': '20px',
        },
        title: {
          color: 'var(--surface-text-normal-low-contrast)',
        },
        image: {
          'border-radius': '20px 20px 0 0',
        },
        cardContent: {
          padding: '0.75rem 0.75rem 0.75rem',
          color: 'var(--content-on-light-high-emphasis-850)',
        },
        information: {
          attentionText: {
            color: 'var(--supporting-moss-700)',
          },
        },
      },
    },
    mostSearchedSectionStyle: {
      style: {
        backgroundImage: {},
        title: {
          container: {
            'padding-left': '0.8rem',
            color: 'var(--content-on-light-high-emphasis-850)',
          },
          image: {
            fill: 'var(--supporting-blue-500)',
            'margin-right': '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          display: 'none',
        },
        card: {
          'background-color': 'var(--surface-a)',
          margin: '1rem 0 0',
          padding: '0',
        },
      },
    },
    merchantAvatarStyle: {
      style: {
        label: {
          'line-height': '1.2rem',
          overflow: 'hidden',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          'text-overflow': 'ellipsis',
          'white-space': 'normal',
          '-webkit-line-clamp': '1',
          width: '100%',
          fontWeight: '600',
        },
        container: {},
      },
    },
    membershipTag: {
      position: 'top-right',
      style: {
        tag: {
          backgroundColor: 'hsla(35, 84%, 54%, .8)',
          padding: '0.5rem',
        },
        label: {
          'font-size': '0.71429rem',
        },
      },
      label: 'Membership',
    },
    loginAlertDarwer: {
      style: {
        'box-shadow': 'unset',
        background: 'transparent',
        padding: '0',
      },
    },
    categoryAvatarStyle: {
      label: {
        width: 'auto !important',
        fontWeight: '600',
        'font-size': '0.875rem',
        color: 'var(--content-on-light-medium-emphasis-600)',
      },
      container: {},
    },
    banners: {
      config: {
        data: [
          {
            redirectUrl: '',
            imageUrl: './assets/Blog-Banner-Examples.webp',
            isExternalUrl: false,
          },
        ],
        showPageTopBanner: true,
        showPageMiddleBanner: true,
      },
    },
    topBannersCarouselOptions: [
      {
        breakpoint: '1280',
        numVisible: 1.1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 1.1,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1.05,
        numScroll: 1,
      },
    ],
    midBannersCarouselOptions: [
      {
        breakpoint: '1280',
        numVisible: 1.2,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 1.2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1.2,
        numScroll: 1,
      },
    ],
    limitedCarouselOptions: [
      {
        breakpoint: '767px',
        numVisible: 2.5,
        numScroll: 1,
      },
      {
        breakpoint: '500px',
        numVisible: 2.2,
        numScroll: 1,
      },
      {
        breakpoint: '400px',
        numVisible: 2.2,
        numScroll: 1,
      },
      {
        breakpoint: '340px',
        numVisible: 2,
        numScroll: 1,
      },
    ],
    bestSellerCarouselOptions: [
      {
        breakpoint: '767px',
        numVisible: 2.5,
        numScroll: 1,
      },
      {
        breakpoint: '500px',
        numVisible: 2.2,
        numScroll: 1,
      },
      {
        breakpoint: '400px',
        numVisible: 2.2,
        numScroll: 1,
      },
      {
        breakpoint: '340px',
        numVisible: 2,
        numScroll: 1,
      },
    ],
    searchBar: {
      style: {
        container: {
          background: 'white',
          border: '0',
        },
        searchIcon: {
          stroke: 'var(--interactive-light-green-text)',
        },
        input: {
          height: '24px',
          fontSize: '0.75rem',
          color: 'var(--content-on-light-disabled-400)',
        },
      },
      config: {
        placeholder: 'Search via category, brand or product',
      },
    },
  },
  listing: {
    pageLayout: {
      chipType: 'category',
      chipOptions: [
        {
          displayName: 'Gift card',
          paramName: 'GiftCard',
        },
        {
          displayName: 'Membership',
          paramName: 'Membership',
        },
        {
          displayName: 'Membership',
          paramName: 'Membership',
        },
      ],
    },
    personalisedCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-.9rem',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '7rem',
          height: '7rem',
        },
        title: {
          image: {
            fill: 'var(--surface-text-normal-low-contrast)',
            'margin-right': '.2rem',
            width: '1rem',
          },
        },
        card: {
          'background-color': 'var(--surface-a)',
          margin: '.5rem 0 0',
          padding: '0 0.7rem 1.5rem',
        },
      },
    },
    card: {
      style: {
        cardContainer: {
          width: '100% !important',
          height: '100% !important',
          borderRadius: '20px',
        },
        image: {
          minHeight: '107px',
          borderRadius: '20px 20px 0 0',
        },
      },
    },
    chip: {
      style: {
        active: {
          container: {
            border: '1px solid var(--interactive-light-primary-action)',
            color: 'var(--content-on-light-high-emphasis-850)',
            fontWeight: '600',
            background: 'var(--surface-a)',
          },
        },
        inActive: {
          container: {
            border: '0',
            color: 'var(--content-on-light-medium-emphasis-600)',
            fontWeight: '600',
          },
        },
      },
    },
    membershipTag: {
      position: 'top-right',
      style: {
        tag: {
          backgroundColor: 'hsla(35, 84%, 54%, .8)',
          padding: '0.5rem',
        },
        label: {
          fontSize: '0.71429rem',
        },
      },
      label: 'Membership',
    },
    navBar: {
      style: {
        container: {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
        },
      },
    },
  },
  shellListing: {
    noBenefitMsg: {
      style: {
        height: '100vh',
      },
    },
    pageLayout: {
      chipType: 'module',
      chipOptions: [
        {
          displayName: 'Gift card',
          paramName: 'GiftCard',
        },
        {
          displayName: 'Membership',
          paramName: 'Membership',
        },
        {
          displayName: 'Offer',
          paramName: 'offers',
        },
      ],
    },
    personalisedCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-.9rem',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '7rem',
          height: '7rem',
        },
        title: {
          image: {
            fill: 'var(--surface-text-normal-low-contrast)',
            marginRight: '.2rem',
            width: '1rem',
          },
        },
        card: {
          backgroundColor: '#fff',
          margin: '.5rem 0 0',
          padding: '0 0.7rem 1.5rem',
        },
      },
    },

    gridContainer: {
      style: {
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        gridTemplateColumns: 'repeat(auto-fit, minmax(45%, 1fr))',
        display: 'grid',
      },
    },
    card: {
      style: {
        cardContainer: { maxHeight: '224px', height: '224px', width: '158px' },
        cardContent: { minHeight: '124px' },
        image: { height: '100px' },
        title: { fontSize: '14px', fontWeight: 800, color: '#000' },
        subTitle: { fontSize: '10px', fontWeight: 200, color: '#000' },
        information: {
          primaryText: { color: '#008659' },
          baseText: {
            color: 'var(--primary-color)',
            attentionText: { color: 'var(--primary-color)' },
          },
        },
      },
    },
    membershipTag: {
      position: 'top-right',
      style: {
        tag: {
          backgroundColor: 'hsla(35, 84%, 54%, .8)',
          padding: '0.5rem',
        },
        label: {
          fontSize: '0.71429rem',
        },
      },
      label: 'Membership',
    },
  },
  benefitDetails: {
    backButton: {
      style: {
        width: '1.6rem',
        fill: 'var(--surface-a)',
      },
    },
    buyButton: {
      style: {
        width: '100%',
      },
    },
    panel: {
      style: {
        container: {
          boxShadow: 'unset',
          borderRadius: '0',
          marginBottom: '.5rem',
        },
        dropDownIcon: {
          width: '1.2rem',
          height: '1.2rem',
        },
      },
    },
  },
  offerDetails: {
    config: {
      redeemButtonLabel: 'Redeem Now',
      claimButtonLabel: 'Claim Now',
    },
    style: {
      panelContainer: {
        borderRadius: '1.125rem',
        background: 'var(--surface-a)',
        boxShadow: '0px 1px 3px 0px var(--black-teritiary)',
        color: 'var(--black-teritiary)',
        overflow: 'hidden',
        marginBottom: '1rem',
        padding: '0.3rem 0',
      },
      panelDropDownIcon: {
        width: '1.2rem',
        height: '1.2rem',
      },
      redeemButton: {
        width: '100%',
        minWidth: '160px',
        height: '48px',
        borderRadius: '2.5rem',
      },
      copyIcon: {
        copyIconStyle: {
          width: '16px',
          height: '16px',
          marginTop: '3px',
          fill: 'var(--primary-color)',
        },
      },
      footerContainer: {
        boxShadow: '0px 0px 1px 0px var(--black-teritiary)',
      },
      backIcon: {
        width: '20px',
        height: '20px',
        fill: 'var(--primary-color)',
      },
      backButtonContainer: {
        backgroundColor: 'unset',
        padding: 0,
        minWidth: 0,
      },
    },

    header: {
      style: {
        avatar: {
          container: {
            width: '4.5rem',
            height: '4.5rem',
          },
          imageOrInitialsContainer: {
            container: {
              width: '100%',
              height: '100%',
            },
          },
        },
        merchantName: {
          fontSize: '16px',
        },
      },
    },
    offerSelection: {
      style: {
        avatar: {
          container: {
            width: '36px',
            height: '36px',
          },
        },
        infoContainer: {
          width: 'calc(100% - 36px)',
        },
        claimButton: {
          color: 'var(--primary-color)',
          letterSpacing: '0.085rem',
          textTransform: 'uppercase',
          background: 'var(--surface-a)',
          fontSize: '0.875rem',
          padding: '0',
          textAlign: 'center',
          lineHeight: '16px',
        },
      },
    },
  },
  header: {
    container: {
      style: {
        background: 'var(--primary-color)',
        padding: '31px 18px',
      },
    },
    logo: {
      config: {
        url: 'https://images.poshvine.com/hdfc-si/HDFC-only-symbol-logo.png',
        onLogoClickNavigationURL: '/home',
      },
    },
    overlay: {
      style: {
        right: '0',
        padding: '0',
        width: '22rem',
        borderRadius: '0',
        marginTop: '14px',
        boxShadow: 'none',
      },
    },
    divider: {
      style: {
        border: '0',
        borderTop: '.7px solid var(--surface-action-icon-low-contrast)',
      },
    },
    logout: {
      style: {
        padding: '0',
        minWidth: 'auto',
      },
    },
    points: {
      style: {
        color: 'var(--surface-0)',
      },
    },
    userProfile: {
      config: {
        type: 'avatar',
      },
    },
    panel: {
      style: {
        container: {
          background: 'transparent',
          boxShadow: 'unset',
          lineHeight: '1.5',
        },
        dropDownIcon: {
          width: '.875rem',
          height: '.875rem',
        },
      },
    },
    loginButton: {
      style: {
        color: '#fff',
        fontSize: '14px',
      },
    },
    searchIcon: {
      style: {
        height: '1.1rem',
        width: '1.1rem',
        fill: 'var(--surface-a)',
      },
      config: {
        imageSrc: 'search',
      },
    },
    coinIcon: {
      style: {
        height: '0.9rem',
        width: '0.9rem',
      },
    },
    backBtnContainer: {
      style: {
        borderRadius: '50%',
        padding: '11px 12px',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.16)',
      },
    },
    backBtn: {
      style: {
        width: '1rem',
        height: '1rem',
        fill: 'var(--primary-color)',
        marginBottom: '2px',
      },
      config: {
        imageSrc: 'back',
      },
    },
    avatar: {
      style: {
        container: {
          width: '1.71429rem',
          height: '1.71429rem',
          margin: '0',
        },
        label: {
          color: '#fff',
          fontSize: '0.71429rem',
          fontWeight: '700',
        },
        imageOrInitialsContainer: {
          initials: {
            background: 'var(--primary-color)',
            fontSize: '0.71429rem',
          },
          container: {
            width: '100%',
            height: '100%',
          },
        },
      },
      config: {
        imgSrc: 'https://images.poshvine.com/hdfc-si/person-outline.png',
      },
    },
  },
  searchPage: {
    modal: {
      style: {
        padding: 0,
      },
    },
    modalContent: {
      style: {
        background: 'white',
      },
    },
    searchResult: {
      config: {
        maxSearchResultsToShow: 20,
        apiErrorHeading: 'Uh oh! Failed to load',
        apiErrorDescription:
          'Something went wrong while connecting to the API. Please reload the page',
        apiErrorButtonLabel: 'Retry',
      },
      style: {
        apiErrorButton: {
          width: '200px',
          height: '48px',
          borderRadius: '20px',
          background: 'var(--interactive-light-primary-action, #00B899)',
        },
        apiErrorButtonSize: 'large',
        emptySearchImage: {
          width: '100px',
        },
      },
    },
    searchBar: {
      style: {
        container: {},
        searchIcon: {
          fill: 'var(--surface-action-icon-low-contrast)',
        },
        input: {
          height: '24px',
          fontSize: '0.875rem',
        },
      },
      config: {
        placeholder: 'Search via category, brand or product',
      },
    },
    recommendedBrands: {
      style: {
        container: {
          height: '100%',
          width: '60px',
        },
        label: {
          fontSize: '12px',
        },
      },
      config: {
        heading: 'Recommended brands',
        maxRecommendedBrandsToShow: 8,
      },
    },
    pastSearch: {
      config: {
        heading: 'Past Search',
        maxSearchHistoryToShow: 3,
      },
    },
    backNavigationByEvent: false,
    backButton: {
      config: {
        backNavigationArrayURL: ['/'],
      },
    },
  },
  authentication: {
    authenticationFlow: {
      config: {
        flow: ['ACTIVATION_CODE', 'MOBILE_SEARCH', 'OTP_VERIFICATION'],
      },
    },
    emailSearch: {
      config: {
        skipEmailSearch: false,
        showBackIcon: false,
        otpTriggerFailureMessage: '',
      },
    },
    drawer: {
      style: {
        height: '45%',
      },
      config: {
        dismissOnBackdropClick: true,
      },
    },
    binValidation: {
      config: {
        skipBinValidation: false,
        heading: 'Enter first and last 4 digits of your IDFC Credit Card',
        shouldValidate: false,
        binType: 'first4_last4',
        errorMessage:
          'Dear customer, please re-check the first 4 and last 4 digits of the card number you have entered',
        submitButtonLabel: 'Continue',
        binSeparator: '_',
      },
      style: {
        binInput: {
          input: {
            width: '25%',
            padding: '20px',
            height: '48px',
          },
          label: {
            fontSize: '0.875rem',
          },
        },
        pciImage: {
          width: '2rem',
        },
        pciLabel: {
          fontSize: '12px',
        },
        button: {
          fontSize: '18px',
        },
        backIcon: {
          display: 'none',
          width: '20px',
          height: '20px',
          fill: 'var(--surface-action-icon-low-contrast)',
        },
      },
    },
    mobileSearch: {
      config: {
        skipMobileSearch: false,
        heading: 'Enter your registered HDFC mobile number',
        mobileSearchErrorMessage: 'Please enter the registered mobile number',
        userCreationErrorMessage: 'Please enter a valid number',
        otpTriggerFailureMessage:
          'You have exceeded the maximum limit for OTP requests. Please try again after some time.',
        submitButtonLabel: 'Send OTP',
        showBackIcon: true,
        shouldCreateUser: false,
        skipAPIValidationOfMobileToNextStep: false,
        consentPrompt: {},
      },
      style: {
        mobileInput: {
          container: {
            display: 'block',
            width: '100%',
          },
          inputBox: {
            height: '48px',
            fontSize: '20px',
          },
          error: {
            marginTop: '8px',
            flexFlow: 'wrap',
            display: 'flex',
            width: '100%',
            fontSize: '1rem',
            fontWeight: 300,
          },
          input: {
            fontSize: '20px',
          },
        },
        label: {
          fontSize: '18px',
          width: '100% !important',
          paddingTop: '16px',
          fontWeight: 600,
          color: 'var(--black)',
        },
        labelContainer: {
          width: '100%',
          textAlign: 'left',
        },
        button: {
          fontSize: '16px',
        },
      },
    },
    activationCode: {
      config: {
        skipActivationCode: true,
        shouldValidate: true,
        heading: 'Enter code sent to you in the SMS/ E-mail',
        errorMessage: 'Please enter your unique number code',
        submitButtonLabel: 'Continue',
        showBackIcon: false,
        minCodeLength: 4,
        skipAPIValidationOfActivationToNextStep: true,
      },
      style: {
        heading: {
          fontSize: '18px',
          fontWeight: 600,
          textAlign: 'justify',
          paddingTop: '16px',
        },
        activationCodeInputBox: { height: '48px' },
      },
    },
    otpVerification: {
      config: {
        skipOtpVerification: false,
        heading: 'Enter OTP',
        errorMessage: 'Invalid OTP. Please enter the correct OTP.',
        resendOtpLabel: 'Did not receive OTP yet?',
        editMobileBtnLabel: 'Change number ?',
        countdownValue: 150,
        submitButtonLabel: 'Verify OTP',
      },
      style: {
        resentOtpLabel: {
          width: '2rem',
          fontSize: '1rem',
        },
        otpInput: {
          input: {
            width: 'inherit',
            maxWidth: '4rem',
          },
          container: {
            width: '100%',
          },
          inputContainer: {
            width: '100%',
          },
          label: {
            fontSize: '16px',
            width: '100% !important',
            paddingTop: '16px',
            fontWeight: 600,
            color: 'var(--black)',
          },
        },
        countdown: {
          container: {
            fontSize: '1rem',
          },
        },
        button: {
          fontSize: '16px',
        },
      },
    },
  },
  redemption: {
    cardDropdown: {
      style: {
        display: 'none',
      },
    },
    benefitHeader: {
      style: {
        knowMoreButton: {
          fontWeight: 700,
          display: 'none',
        },
      },
    },
  },
  checkout: {
    style: {
      paymentMethodAvailablePointsIcon: {
        width: '12px',
        height: '12px',
      },
      buyButton: {
        width: '200px',
        height: '100%',
      },
    },
    config: {
      paymentMethodContainerHeading: 'Payment method',
      paymentMethodContainerNote: 'Discount is applicable on Card Payout only!',
      payUsingCardTitle: 'Pay using cards',
      payWithPointsAndCardTitle: 'Pay using your Points + Card',
      rewardsPointsUsedDesc: 'Used reward points',
      availablePointsDesc: 'Available points',
      billDetailsHeading: 'Bill Details',
      billDetailsGcTotalLabel: 'Gift Card Total',
      billDetailsMemTotalLabel: 'Membership Total',
      valueOfRewardPointsLabel: 'Value of Reward Points',
      maxRedeemableAmountOverflowMessage:
        'We have applied the max number of points applicable for the transaction',
      bogoBenefitTagLabel: 'Buy 1 Get 1 Free',
    },
  },
  giftcardRedemption: {
    footer: {
      style: {
        buyButton: {
          width: '200px',
          height: '48px',
        },
        circleCheckIcon: {
          fill: 'var(--feedback-background-positive-high-contrast )',
          width: '0.75rem',
          height: '1rem',
        },
      },
      config: {
        bogoRibbonLabel: 'Buy 1 Get 1 Unlocked',
      },
    },
    amountQuantityComponent: {
      config: {
        maximumOrderValueAllowed: 5,
        maxAllowedQuantityPerDenomination: 5,
        maxAllowedUniqueDenominations: 4,
      },
    },
  },
  membershipRedemption: {
    footer: {
      style: {
        buyButton: {
          width: '200px',
          height: '48px',
        },
        circleCheckIcon: {
          fill: 'var(--feedback-background-positive-high-contrast )',
          width: '0.75rem',
          height: '1rem',
        },
      },
      config: {
        bogoRibbonLabel: 'BOGO Unlocked',
      },
    },
  },
  orderDetails: {
    metaInfoDrawerPanel: {
      style: {
        container: { padding: '1rem' },
        header: {},
        content: {},
      },
    },
    homeNavigationPath: [''],
    divider: {
      style: {
        border: '0',
        borderTop: '.7px solid #F3F3F3',
      },
    },
    topBar: {
      style: {
        position: 'relative',
        zIndex: '1',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
        fontSize: '17px',
      },
    },
    buyButton: {
      style: {
        width: '100%',
        height: '60px',
        background: 'var(--primary-color)',
        fontSize: '1rem',
        borderRadius: '32px',
        textTransform: 'uppercase',
      },
    },
    learnButton: {
      style: {
        color: 'var(--primary-color)',
        padding: '0',
        marginBottom: '2rem',
      },
    },
    statusIcon: {
      style: {
        width: '1.14286rem',
        height: '1.14286rem',
      },
    },
    backArrow: {
      style: {
        fill: 'var(--primary-color)',
        width: '35px',
        height: '35px',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      },
    },
    panel: {
      style: {
        container: {
          background: 'transparent',
          boxShadow: 'unset',
          lineHeight: '1.5',
        },
        dropDownIcon: {
          width: '1.87rem',
          height: '1.87rem',
          fill: 'var(--primary-color)',
        },
      },
    },
  },
  orderHistory: {
    pageLayout: {
      style: {
        backgroundColor: 'white',
      },
      moduleChipsVisible: true,
      chipOptions: [
        {
          displayName: 'Bundle',
          paramName: 'BundleBooking',
        },
        {
          displayName: 'Gift Cards',
          paramName: 'GiftCardBooking',
        },
        {
          displayName: 'Memberships',
          paramName: 'MembershipBooking',
        },
        {
          displayName: 'Offers',
          paramName: 'OfferBooking',
        },
      ],
      dateFormat: 'dd MMM yyyy',
    },
    navBar: {
      style: {
        backgroundColor: 'white',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
        position: 'relative',
        zIndex: '1',
      },
    },
    chip: {
      style: {
        active: {
          container: {
            width: 'auto',
            whiteSpace: 'nowrap',
            border: 'unset',
            color: 'var(--surface-a)',
            backgroundColor: 'var(--primary-color)',
            margin: '0 1rem 1.2rem 0',
          },
          label: {
            fontWeight: '700',
          },
        },
        inActive: {
          container: {
            width: 'auto',
            whiteSpace: 'nowrap',
            border: 'unset',
            color: 'var(--surface-a)',
            backgroundColor: 'var(--black)',
            margin: '0 1rem 1.2rem 0',
          },
          label: {
            fontWeight: '700',
          },
        },
      },
    },
    backArrow: {
      style: {
        fill: 'var(--primary-color)',
        width: '35px',
        height: '35px',
        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      },
      navigationPath: [''],
    },
    navBarTitle: {
      style: {
        color: '#404040',
        fontSize: '17px',
        fontWeight: '700',
      },
    },
    statusIcon: {
      style: {
        width: '0.85714rem',
        height: '0.85714rem',
      },
    },
    benefitButton: {
      style: {
        padding: '.2rem 0',
        fontWeight: '700',
        fontSize: '.86rem',
        color: 'var(--primary-color)',
        textTransform: 'uppercase',
      },
    },
    card: {
      card: {
        padding: '0.7rem 0',
      },
      title: {
        fontWeight: '700',
        fontSize: '0.86rem',
      },
    },
    divider: {
      style: {
        border: '0',
        borderTop: '.7px solid #F3F3F3',
      },
    },
  },
  orderStatus: {
    homeNavigationPath: [''],
    buyButton: {
      style: {
        width: '100%',
        background: 'var(--brand-primary-500)',
      },
    },
    benefitButton: {
      style: {
        padding: '.2rem 0',
        fontWeight: '700',
        fontSize: '.86rem',
        color: 'var(--brand-primary-500)',
      },
    },
    card: {
      title: {
        fontWeight: '700',
        fontSize: '0.86rem',
      },
      container: {
        padding: '20px 16px',
      },
    },
    statusIcon: {
      style: {
        width: '3.42rem',
        height: ' 3.42rem',
      },
    },
    cardStatusIcon: {
      style: {
        lineHeight: '1.8',
      },
    },
  },
  paymentStatus: {
    style: {
      statusIcon: {
        success: {
          width: '72px',
          height: '72px',
          fill: 'var(--feedback-background-positive-high-contrast)',
        },
        error: {
          width: '70px',
          height: '70px',
          fill: 'var(--feedback-background-negative-high-contrast)',
        },
      },
    },
  },
  pointsLedger: {
    desktop: {
      style: {
        backIcon: {
          width: '24px',
          height: '24px',
        },
        heading: {
          fontSize: '14px',
        },
      },
    },
    outerContainer: {
      style: {
        backgroundColor: 'var(--surface-background-level2-low-contrast)',
      },
    },
    pageStyle: {
      backgroundColor: 'var(--surface-background-level1-low-contrast)',
    },
    earnMore: {
      container: {
        config: {
          label: 'Available Ketto hearts =',
          desc: 'Get exclusive offers, gift cards & much more',
          ctaLabel: 'Earn More',
          clipIconSrc: 'clip',
          heartIconSrc: 'heart',
        },
        cta: {
          style: {
            letterSpacing: '1px',
          },
        },
      },
      clipIcon: {
        style: {
          width: '39px',
          height: '53px',
        },
      },
      heartIcon: {
        style: {
          width: '26px',
          height: '24px',
          top: '13px',
          left: '8px',
        },
      },
    },
  },
  offersListing: {
    chip: {
      style: {
        inActive: {
          container: {
            width: 'auto',
            whiteSpace: 'nowrap',
            border: 'unset',
            color: 'var(--surface-text-subdued-low-contrast)',
          },
          label: {
            fontWeight: '700',
          },
        },
        active: {
          container: {
            width: 'auto',
            whiteSpace: 'nowrap',
            border: 'unset',
            color: 'var(--surface-a)',
            backgroundColor: 'var(--primary-color)',
          },
          label: {
            fontWeight: '700',
          },
        },
      },
    },
    card: {
      style: {
        image: {
          // TODO: create reUsable variable in common for generic styling. ref: https://github.com/poshvine-rzp/poshvine-customer-journeys/pull/60#discussion_r1335379919
          minHeight: '107px',
        },
      },
    },
    navBar: {
      style: {
        container: {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
          backgroundColor: 'var(--surface-a)',
        },
        backIcon: {
          width: '1rem',
          height: '1rem',
          margin: '0 auto',
          fill: 'var(--primary-color)',
        },
      },
    },
  },
  tokenExpired: {
    config: {
      title: 'Your login token has expired',
      tokenExpiredIcon: 'hour-glass',
      description: 'Please re-authenticate to continue',
      reAuthenticateCtaButton: {
        isRedirectedFromApp: true,
      },
    },
    style: {
      tokenExpiredIcon: {
        width: '60px',
        height: 'auto',
        fill: 'var(--primary-color)',
      },
      reAuthenticateCtaButton: {
        width: '160px',
        height: '50px',
      },
    },
  },
  loginBanner: {
    loginButton: {
      width: '9rem',
      borderRadius: '0',
      boxShadow: 'none',
      fontSize: '1rem',
      fontWeight: '700',
      height: '2.86rem',
    },
    iconStyle: {
      width: '8rem',
      height: '8rem',
    },
  },
  offerRedemption: {},
  bundleDetails: {
    circularWavyFilledTick: {
      style: {
        fill: 'green',
        width: '16px',
        height: '16px',
      },
    },
    processSubscriptionButton: {
      style: {
        borderRadius: '7px',
        height: '57px',
        width: '55vw',
        fontSize: '16px',
        fontWeight: 700,
      },
    },
    continueToOrderHistoryButton: {
      style: {
        borderRadius: '7px',
        height: '57px',
        width: '100%',
        fontSize: '16px',
        fontWeight: 700,
      },
    },
    benefitHeader: {
      style: {
        knowMoreButton: {
          fontWeight: 700,
          display: 'none',
        },
      },
      config: {},
    },
    preSubscriptionModalOpen: {
      style: {
        footerButton: {
          width: '100%',
        },
      },
      config: {
        headerTemplate: {
          bankImageURL:
            'https://images.poshvine.com/hdfc-salary/HDFC-Bank-Reverse-Logo.png',
        },
        contentTemplate: {
          text: `Are you sure you want to proceed with one time payment? You can avail 50%
          discount on your vouchers by subscribing for 12 months`,
        },
        isModalFlowEnabled: true,
      },
    },
  },
};
