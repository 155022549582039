<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="relative">
  <div
    class="banner-fold flex justify-content-center overflow-hidden relative bg-primary"
    [ngClass]="{ 'pre-login-banner-fold-height': !user }">
    <img
      class="absolute opacity-30 bottom-0 left-0"
      src="https://images.poshvine.com/hdfc-si/DOTS.png"
      alt="dots" />

    <div class="left-pane relative z-1 md:w-5">
      <h1 class="pv-text-24px md:pv-text-29px text-center md:text-left">
        Get 50% discount on Gift Vouchers for
        <span class="text-yellow-400 font-bold">next 12 months</span>
        {{ user ? '' : ' for your HDFC Bank Credit Card.' }}
      </h1>

      <pv-button
        *ngIf="!user"
        class="block md:hidden pv-mt-24px desktop-get-started-button"
        label="Get Started"
        (clickEvent)="toggleAuthModal()"
        styleClass="bg-white text-primary pv-text-16px pv-px-8px w-full"></pv-button>
      <hdfc-si-benefits-explainer></hdfc-si-benefits-explainer>

      <pv-button
        *ngIf="!user"
        class="hidden md:block custom-margin-for-button"
        [style]="getStartedButtonStyleOverride"
        label="Get Started"
        (clickEvent)="toggleAuthModal()"
        styleClass="bg-white text-primary pv-text-20px pv-px-8px"></pv-button>
    </div>
    <div
      class="right-pane relative z-1 w-5 justify-content-end align-items-center hidden md:flex">
      <img
        src="https://images.poshvine.com/hdfc-si/RupayCard.png"
        alt="Rupay card"
        class="rupay-credit-card-image" />
    </div>
  </div>
  <ng-template #gcmsListing>
    <div
      [ngStyle]="defaultGrabOffersCarouselStyling.bgStyling.container"
      *ngFor="let benefit of parsedResponse"
      class="redemption-steps-card-container">
      <div class="md:pv-mr-20px relative pv-m-12px">
        <pv-card
          [offerImage]="benefit.headerImage"
          [information]="benefit.information ?? {}"
          [title]="benefit.title"
          [subTitle]="benefit.subTitle"
          [cardRibbon]="benefit.cardRibbon ?? null"
          [metaInformation]="benefit?.cardMeta"
          (cardClickHandler)="
            handleRedeemWrapperGCAndMemberships(
              benefit.cardMeta.id,
              benefit.title,
              benefit.isUnlockedBenefitApplicable
            )
          "
          [style]="moduleConfig?.personalisedCardStyle"
          [cardBanner]="
            benefit.benefitType === 'Membership'
              ? (moduleConfig?.membershipTag ?? null)
              : null
          "
          cardType="v1"
          [analyticsEnabledForCardButton]="true"
          [labelForAnalyticsCard]="
            gtmLabels.landing.personalisedOffer
          "></pv-card>
        <div
          *ngIf="!user"
          class="overlay bg-black-alpha-70 absolute top-0 left-0 flex align-items-center justify-content-center cursor-pointer"
          [style]="moduleConfig?.personalisedCardStyle?.['cardContainer']"
          (click)="toggleAuthModal()">
          <div
            class="locked-container flex flex-column justify-content-center align-items-center text-white md:pv-text-14px pv-text-12px">
            <img
              class="pv-pb-4px"
              alt="lock"
              src="../../assets/lock-icon.png" />
            Locked
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #bundleListing>
    <ng-container *ngFor="let benefit of bundleBenefitsArray">
      <div class="m-4 pv-mb-20px md:pv-mb-30px relative">
        <pv-card
          [offerImage]="benefit.headerImage"
          [information]="benefit?.information ?? {}"
          [title]="benefit.title"
          [subTitle]="benefit.subTitle"
          [cardRibbon]="benefit.cardRibbon ?? null"
          [metaInformation]="benefit?.cardMeta"
          (cardClickHandler)="
            handleBundleRedemption(bundleBenefitCampaignID, benefit.cardMeta.id)
          "
          [style]="{ information: { baseText: { color: 'green' } } }"
          [style]="moduleConfig?.personalisedCardStyle"
          [cardBanner]="
            benefit.benefitType === 'Membership'
              ? (moduleConfig?.membershipTag ?? null)
              : null
          "
          cardType="v1"
          [analyticsEnabledForCardButton]="true"
          [labelForAnalyticsCard]="
            gtmLabels.landing.personalisedOffer
          "></pv-card>
        <div
          *ngIf="!user"
          class="overlay bg-black-alpha-70 absolute top-0 left-0 flex align-items-center justify-content-center cursor-pointer"
          [style]="moduleConfig?.personalisedCardStyle?.['cardContainer']"
          (click)="toggleAuthModal()">
          <div
            class="locked-container flex flex-column justify-content-center align-items-center text-white md:pv-text-14px pv-text-12px">
            <img
              class="pv-pb-4px"
              alt="lock"
              src="../../assets/lock-icon.png" />
            Locked
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <ng-template
    #listingSection
    let-title="title"
    let-imageTemplate="imageTemplate"
    let-bgStyling="bgStyling"
    let-bgClass="bgClass"
    let-isEnableViewAll="isEnableViewAll"
    let-listingTemplate="listingTemplate"
    let-isBundle="isBundle">
    <hdfc-si-benefits-carousel
      [title]="title"
      [benefitOverrideConfig]="bgStyling"
      [isVertical]="true"
      [showArrows]="false"
      [customClass]="isBundle ? 'redemption-steps-bundle-card-container' : ''">
      <div
        titleTemplateContent
        class="flex pv-mb-16px w-full flex-column md:flex-row text-left pv-text-30px font-bold font-bold md:pv-text-24px md:pv-mb-6px text-black-alpha-90 relative">
        <span class="pv-mb-8px md:m-0" [ngClass]="bgClass?.['heading']">
          {{ title }}
        </span>
      </div>
      <ng-container>
        <ng-container [ngTemplateOutlet]="listingTemplate"></ng-container>
      </ng-container>
    </hdfc-si-benefits-carousel>
  </ng-template>
  <ng-template #imageTemplateBurst>
    <!-- Update image here -->
  </ng-template>
  <ng-container *ngIf="areBenefitsLoading && user">
    <hdfc-si-benefits-carousel [showArrows]="!!parsedResponse.length">
      <div
        titleTemplateContent
        class="flex pv-mb-16px w-full flex-column md:flex-row text-left pv-text-30px font-bold font-bold md:pv-text-24px md:pv-mb-22px text-black-alpha-90 relative">
        <span class="pv-mb-8px md:m-0">
          <p-skeleton width="35rem" height="2rem"></p-skeleton>
        </span>

        <p-skeleton
          class="pv-ml-30px"
          width="8rem"
          height="2rem"
          borderRadius="16px"></p-skeleton>
      </div>
      <ng-container *ngFor="let card of [1, 2, 3, 4, 5]">
        <div class="pv-mr-20px relative">
          <div
            class="border-round border-1 surface-border p-3 surface-card"
            [style]="moduleConfig?.personalisedCardStyle?.['cardContainer']">
            <p-skeleton width="100%" height="100px"></p-skeleton>
            <p-skeleton
              width="100%"
              height="2rem"
              styleClass="mb-2 mt-3"></p-skeleton>
            <div class="flex justify-content-between mt-3">
              <p-skeleton width="4rem" height="2rem"></p-skeleton>
              <p-skeleton width="4rem" height="2rem"></p-skeleton>
            </div>
          </div>
        </div>
      </ng-container>
    </hdfc-si-benefits-carousel>
  </ng-container>
  <ng-container *ngIf="!areBenefitsLoading && user">
    <ng-container
      *ngTemplateOutlet="
        listingSection;
        context: {
          title: 'Select your brand',
          imageTemplate: imageTemplateBurst,
          isEnableViewAll: false,
          listingTemplate: bundleListing,
          isBundle: true
        }
      "></ng-container>
    <ng-container *ngIf="SHOW_REGULAR_BENEFITS">
      <ng-container
        *ngTemplateOutlet="
          listingSection;
          context: {
            title: 'Grab deals for Gift Cards',
            imageTemplate: imageTemplateBurst,
            bgStyling: defaultGrabOffersCarouselStyling.bgStyling,
            bgClass: defaultGrabOffersCarouselStyling.bgClass,
            isEnableViewAll: defaultGrabOffersCarouselStyling.isEnableViewAll,
            listingTemplate: gcmsListing
          }
        "></ng-container>
    </ng-container>
  </ng-container>
</div>

<pv-authentication
  class="block"
  *ngIf="showAuthenticationModal"
  [isVisible]="showAuthenticationModal">
  <div
    class="hdfc-logo flex justify-content-center align-items-center w-full pv-mt-10px"
    externalHeaderTemplate>
    <img
      src="https://images.poshvine.com/hdfc-si/HDFC-logo.png"
      alt="HDFC Logo" />
  </div>
</pv-authentication>

<gv-redemption
  *ngIf="showGvRedemption"
  [showRedemption]="showGvRedemption"
  [showCheckout]="false"
  [benefitId]="selectedBenefitId"
  [isBenefitOffer]="isBenefitOffer"
  [selectedOfferId]="offerId"
  (redemptionClosed)="handleGVRedemptionClose($event)"></gv-redemption>

<gv-bundle-details
  *ngIf="campaignId"
  [campaignId]="campaignId"
  [bundleId]="bundleId"
  (closeBundleEvent)="closeBundleEventHandler()"></gv-bundle-details>
