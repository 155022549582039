import { ContextualEnvironment } from '@pv-frontend/contextual-journey/interface/contextual-journey.interface';
import { GvEnvironment } from '@pv-frontend/gift-voucher-journey';

interface EnvInterface extends GvEnvironment {
  clientId: string;
  projectId: string;
  countryId: string;
  corePathV2: string;
  sentryDsn: string;
  production: boolean;
}

// TODO: rename the variables with proper convention, blocker for this is razorpay-pg lib.
export const environment: EnvInterface &
  ContextualEnvironment & { bundleCampaignId: string } = {
  production: false,
  apiPerksServiceUrl: 'https://api-customer.poshvine.com/ps/v1',
  apiPerksServiceUrlV2: 'https://api-customer.poshvine.com/ps/v2',
  apiClientsServiceUrl: 'https://api-customer.poshvine.com/cs/v1',
  apiUrlPaV1: 'https://api-customer.poshvine.com/pa/v1/',
  apiBookingServiceUrl: 'https://api-customer.poshvine.com/bs/v1',
  clientId: 'd8d89f10-26ff-4ffd-8c84-3232936550c5',
  projectId: '87bc617e-50a8-494a-9820-0d83a93107f9',
  countryId: '625a3e8e-e493-421a-8ece-609f10b37442',
  campaignPath: 'https://api-customer.poshvine.com/cas/v1/',
  campaignEnterprisePath: 'https://api-customer.poshvine.com/cas/enterprise/',
  coreServicePath: 'https://api-customer.poshvine.com/core_service/v1',
  apiLoyaltyServiceUrl: 'https://api-customer.poshvine.com/ls/v1',
  bundleCampaignId: 'bec3ef24-4101-4d6a-9e89-84766e7c5602',
  corePathV2: 'https://sandbox-api.poshvine.com/cs/v1',
  apiBookingServiceUrlV2: 'https://api-customer.poshvine.com/bs/v2',
  campaignPathV2: 'https://api-customer.poshvine.com/cas/v2',
  sentryDsn:
    'https://232e4eda16825c6ae4f557bf85b6b61f@o515678.ingest.us.sentry.io/4507457232961536',
  apiUrlLsV1: '',
};
