<div class="">
  <ul class="list-none p-0 m-0">
    <li
      *ngFor="let benefit of benefits; let i = index"
      class="benefit-container flex justify-content-start align-items-center md:pv-mb-16px text-white">
      <div
        *ngIf="isUserLoggedIn"
        class="index border-circle flex align-items-center justify-content-center pv-text-16px font-bold pv-mr-16px overflow-hidden">
        {{ i + 1 }}
      </div>
      <div class="content font-bold" [innerHTML]="benefit?.title">
        <br />
        <span class="subcontent font-normal text-secondary font-light"></span>
      </div>
    </li>
  </ul>
  <ng-container *ngIf="!isUserLoggedIn">
    <img
      *ngIf="!deviceService.isMobile"
      src="https://images.poshvine.com/hdfc-si/brand-logos-desktop-white-background.png"
      alt="brands-to-choose-from-desktop"
      class="desktop-size pv-mt-20px" />
    <div
      class="w-full flex pv-mt-40px justify-content-center align-items-center">
      <img
        *ngIf="deviceService.isMobile"
        src="https://images.poshvine.com/hdfc-si/brand-logos-mobile-white-background.png"
        alt="brands-to-choose-from-mobile"
        class="mobile-size" />
    </div>
  </ng-container>
</div>
