<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  class="unlocked-bundle-fold relative z-1 bg-white w-full flex justify-content-center flex-column flex-wrap overflow-hidden md:mb-8"
  [style]="benefitOverrideConfig?.['container']">
  <ng-content select="[titleTemplateContent]"></ng-content>

  <div
    *ngIf="!isVertical"
    #stepsContainerRef
    [style]="benefitOverrideConfig?.['horizontalScrollContainer']"
    class="redemption-steps-card-container relative w-full overflow-x-scroll pv-py-18px pv-my-1px flex flex-row justify-content-start align-items-center">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="isVertical"
    #stepsContainerRef
    [style]="benefitOverrideConfig?.['horizontalScrollContainer']"
    [class]="
      'redemption-steps-card-container flex justify-content-start flex-row flex-wrap relative w-full pv-py-18px pv-my-1px justify-content-start align-items-center ' +
      customClass
    ">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="showArrows"
    [style]="benefitOverrideConfig?.['arrowContainer']"
    class="hidden backward-forward w-10 md:flex justify-content-start align-items-center">
    <div
      class="circle flex justify-content-center align-items-center cursor-pointer"
      (click)="scrollHorizontal(true)"
      [ngClass]="{ active: !isStepsContainerMinWidthReached }">
      <pv-svg-icon name="back"></pv-svg-icon>
    </div>
    <div
      class="circle flex justify-content-center align-items-center pv-ml-13px cursor-pointer"
      (click)="scrollHorizontal(false)"
      [ngClass]="{ active: !isStepsContainerMaxWidthReached }">
      <pv-svg-icon
        name="back"
        [style]="{ transform: 'rotate(180deg)' }"></pv-svg-icon>
    </div>
  </div>
  <ng-content select="[footerTemplateContent]"></ng-content>
</div>
