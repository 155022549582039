import { GiftVouchersConfig } from '@pv-frontend/gift-voucher-journey';

import { giftVouchersConfig } from './gift-vouchers';
import { giftVouchersDesktopConfig } from './gift-vouchers-desktop';

export interface AllJourneyConfig {
  journey: GiftVouchersConfig;
}

export const allJourneyDetails: Record<string, unknown> = {
  journey:
    window.innerWidth < 768 ? giftVouchersConfig : giftVouchersDesktopConfig, // TODO: optimize this condition better
};
