import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BUNDLE_MODES_OF_PAYMENT_CONST,
  BenefitType,
  DeviceService,
  GTMTrackLabels,
  SharedGVLogicService,
} from '@pv-frontend/gift-voucher-journey';
import { landingConfigOverride } from '@pv-frontend/gift-voucher-journey/home/home.interface';
import { BenefitResponseDisp } from '@pv-frontend/gift-voucher-journey/interfaces/benefit-response';
import { UserData } from '@pv-frontend/header';
import {
  CategoryIconInput,
  ChipStyleOverride,
} from '@pv-frontend/pv-shared-components/chip';
import { OverrideStyleConfig } from '@pv-frontend/pv-shared-components/common/types';
import {
  JourneyMetadataService,
  JOURNEY_METADATA_SERVICE_TOKEN,
} from '@pv-frontend/pv-shared-services/journey-metadata-service';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Subject, finalize, takeUntil } from 'rxjs';

import { environment } from '../../environments/environment';
import { APIService } from '../api.service';
import { AllBenefitsResponse, BundleRedemptionResponse } from '../hdfc.types';

@Component({
  selector: 'hdfc-si-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public getStartedButtonStyleOverride: OverrideStyleConfig = {
    fontSize: '20px',
    padding: '15px 24px',
  };
  public showAuthenticationModal = false;
  public chipIconObject: CategoryIconInput = {
    url: '../../assets/star.svg',
    position: 'start',
  };
  public chipStyle: ChipStyleOverride = {
    container: {
      border: '0',
      fontStyle: '14px',
      color: 'var(--black)',
      width: 'max-content',
      background: 'var(--green-100)',
      padding: '6px 12px',
    },
    categoryIcon: {
      height: '20px',
      width: '20px',
    },
  };
  public readonly SHOW_REGULAR_BENEFITS = false;
  public campaignId: string = '';
  public bundleId: string = '';
  public user?: UserData;
  public moduleConfig?: Partial<landingConfigOverride> = {};
  private destroy$: Subject<void> = new Subject<void>();
  public bundleBenefitsArray: BenefitResponseDisp[] = [];
  public parsedResponse: BenefitResponseDisp[] = [];
  public areBenefitsLoading: boolean = false;
  public defaultGrabOffersCarouselStyling = {
    bgStyling: { container: { background: 'var(--surface-0)' } },
    bgClass: { heading: 'text-black-alpha-90' },
    isEnableViewAll: false,
  };
  public bundleBenefitCampaignID = environment.bundleCampaignId;
  public showGvRedemption: boolean = false;
  public isBenefitOffer = false;
  public usePoints = 0;
  public selectedBenefitId = '';
  public offerId?: string;
  public constructor(
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,
    private apiService: APIService,
    private sharedLogicService: SharedGVLogicService,
    @Inject('gtmLabels') public gtmLabels: GTMTrackLabels,
    @Inject(JOURNEY_METADATA_SERVICE_TOKEN)
    public journeyMetadataService: JourneyMetadataService,
    public deviceService: DeviceService,
    private router: Router
  ) {
    this.pvUserService.loginModalObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((showAuthenticationModal: boolean) => {
        this.showAuthenticationModal = showAuthenticationModal;
        this.apiService.reloadHeaderEvent.next();
        this.router.navigate(['home']);
      });
    this.pvUserService.userLoggedInObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.router.navigate(['']);
          this.showAuthenticationModal = false;
          this.user = this.pvUserService.getUserDetails() ?? undefined;
        }
      });
    this.setStyleConfig('journey.landingPage');
    if (this.deviceService.isMobile) {
      this.defaultGrabOffersCarouselStyling = {
        bgStyling: { container: { background: 'var(--grab-deals-bg-color)' } },
        bgClass: { heading: 'text-white' },
        isEnableViewAll: true,
      };
    }
  }
  public ngOnInit(): void {
    this.user = this.pvUserService.getUserDetails() ?? undefined;
    if (this.SHOW_REGULAR_BENEFITS) {
      this.fetchAndSortBenefits();
    }
    this.fetchBundles();
  }

  private fetchBundles(): void {
    this.areBenefitsLoading = true;
    this.apiService.getCampaignData().subscribe(
      (res: BundleRedemptionResponse) => {
        this.areBenefitsLoading = false;
        if (res.data.length) {
          this.bundleBenefitsArray = res.data.map<BenefitResponseDisp>(
            (bundleRedemptionDetail) => {
              const information = {
                strikeThroughText: '',
                baseText: 'Subscribed',
              };
              if (
                !bundleRedemptionDetail?.attributes?.campaign_claimable ||
                bundleRedemptionDetail.attributes.campaign_claimable.claimable
              ) {
                if (
                  bundleRedemptionDetail?.attributes?.bundle_details?.data
                    ?.attributes?.benefit_discounts?.length
                ) {
                  let discount = 0;
                  let bundleIndex =
                    bundleRedemptionDetail.attributes.bundle_details.data.attributes.benefit_discounts.findIndex(
                      (discountItem) =>
                        discountItem.applicable_on ===
                        BUNDLE_MODES_OF_PAYMENT_CONST.SCHEDULE
                    );
                  if (bundleIndex === -1) {
                    bundleIndex =
                      bundleRedemptionDetail.attributes.bundle_details.data.attributes.benefit_discounts.findIndex(
                        (discountItem) =>
                          discountItem.applicable_on ===
                          BUNDLE_MODES_OF_PAYMENT_CONST.CARD_PAYMENT
                      );
                  }
                  discount =
                    bundleRedemptionDetail.attributes.bundle_details.data
                      .attributes.benefit_discounts[bundleIndex].value;
                  information.strikeThroughText =
                    '₹' +
                    bundleRedemptionDetail.attributes.bundle_details.data
                      .attributes.amount;
                  information.baseText =
                    '₹' +
                    (bundleRedemptionDetail.attributes.bundle_details.data
                      .attributes.amount *
                      (100 - (discount ?? 0))) /
                      100;
                } else {
                  information.baseText =
                    '₹' +
                    bundleRedemptionDetail.attributes.bundle_details.data
                      .attributes.amount;
                }
              }
              return {
                headerImage:
                  bundleRedemptionDetail.attributes.bundle_details.data
                    .attributes.image_url ??
                  'https://images.poshvine.com/icon/gv/rectangular-image-placeholder.jpeg',
                title: '',
                subTitle:
                  bundleRedemptionDetail.attributes.bundle_details.data
                    .attributes.title,
                cardMeta: {
                  type: '',
                  id: bundleRedemptionDetail.attributes.bundle_redemption_option
                    .data.id,
                },
                // Will need to add Bundle as the benefit type
                benefitType: 'bundle' as BenefitType,
                information,
              };
            }
          );
        }
      },
      (err) => {
        console.error('~ HomeComponent ~ fetchBundles ~ err:', err);
        this.areBenefitsLoading = false;
      }
    );
  }

  private fetchAndSortBenefits(): void {
    this.areBenefitsLoading = true;
    this.apiService
      .getAllBenefits({
        per_page: 100,
      })
      .pipe(
        finalize(() => {
          this.areBenefitsLoading = false;
        })
      )
      .subscribe((res: AllBenefitsResponse) => {
        if (res.data.length) {
          /* This sorting is needed as BE cant handle it for now */
          const sortingOrderPrecedenceArray = [
            'Amazon Shopping Voucher',
            'Amazon Pay eGift Card',
            'Amazon Pay E-Gift Card',
            'Flipkart E-Gift Card',
          ];
          const benefitsArray: BenefitResponseDisp[] = [];
          benefitsArray.push(
            ...res.data.map((element) =>
              this.sharedLogicService.generateBenefitCardData(element)
            )
          );
          if (benefitsArray?.length) {
            benefitsArray.sort((benefit) =>
              sortingOrderPrecedenceArray?.includes(benefit.merchantName ?? '')
                ? -1
                : 1
            );
            this.parsedResponse = benefitsArray;
          }
        }
      });
  }
  public handleRedeemWrapperGCAndMemberships(
    merchantId: string,
    merchantName: string,
    isUnlockedBenefitApplicable?: boolean
  ): void {
    const queryParams = {
      merchantId,
      merchantName,
      ...{ isBenefitOffer: false },
      isUnlockedBenefitApplicable,
    };
    if (this.deviceService.isMobile) {
      this.showGvRedemption = true;
      this.selectedBenefitId = merchantId;
      this.isBenefitOffer = false;
      this.offerId = '';
    } else {
      this.handleGVRedemptionClose(false);
      this.router.navigate(['gvms-redeem'], {
        queryParams: queryParams,
      });
    }
  }

  private setStyleConfig(pathToView: string): void {
    this.moduleConfig = this.journeyMetadataService.getJourneyConfig(
      pathToView
    ) as Record<string, unknown>;
  }

  public toggleAuthModal(): void {
    this.showAuthenticationModal = !this.showAuthenticationModal;
  }

  public handleGVRedemptionClose(showRedemption: boolean): void {
    this.showGvRedemption = showRedemption;
    this.selectedBenefitId = '';
  }

  public handleBundleRedemption(campaignId: string, bundleId: string): void {
    if (this.deviceService.isMobile) {
      this.campaignId = campaignId;
      this.bundleId = bundleId;
    } else {
      this.router.navigate(['bundle-details', campaignId, bundleId]);
      /* Will remove this once the bundle details PR gets merged into poshvine customer journey repo
      window.open('http://localhost:4200/bundle-details/' + campaignId + '/' + campaignId) */
    }
  }
  public closeBundleEventHandler(): void {
    this.campaignId = '';
  }
}
