import { GTMTrackLabels } from '@pv-frontend/gift-voucher-journey';

import { FooterItem, MenuItem } from './hdfc.types';

export const appConstants: {
  trackLabels: GTMTrackLabels;
  menuItems: MenuItem[];
  footerItems: FooterItem[];
} = {
  trackLabels: {
    search: {
      recommendedBrands: 'gv_search.recommended_brands.gv_unit_clicked',
      pastSearch: 'gv_search.past_search.gv_unit_clicked',
      searchResults: 'gv_search.search_results.gv_unit_clicked',
    },
    landing: {
      companyIcon: 'gv_landingpage.company_icon_clicked',
      loginBanner: 'gv_landingpage.login_rendered',
      topBanner: 'gv_landingpage.banner_clicked',
      homeSearch: 'gv_landingpage.search_clicked',
      category: 'gv_landingpage.category_clicked',
      personalisedOfferSection:
        'gv_landingpage.personalised_offer_clicked_offer.view_all_clicked',
      personalisedOffer: 'gv_landingpage.personalised_offer_clicked',
      limitedOfferSection: 'gv_landingpage.limited_offers.view_all_clicked',
      limitedOffer: 'gv_landingpage.limited_offer_clicked',
      mostSearchedBrand: 'gv_landingpage.most_searched_brand_clicked',
      bestsellerOfferSection:
        'gv_landingpage.bestseller_offer.view_all_clicked',
      bestsellerOffer: 'gv_landingpage.bestseller_offer_clicked',
      topOfferSection: 'gv_landingpage.top_offer.view_all_clicked',
      topOffer: 'gv_landingpage.top_brands_clicked',
    },
  },
  menuItems: [
    { label: 'Home', code: 'home', url: '', target: '_self' },
    {
      label: 'FAQs',
      code: 'faq',
      url: 'info/faq',
      target: '_self',
      isHiddenPostLogin: true,
    },
    {
      label: 'Contact Us',
      code: 'contact-us',
      url: 'info/customer-support',
      target: '_self',
      isHiddenPostLogin: true,
    },
  ],
  footerItems: [
    {
      label: 'Program T&C',
      url: 'info/terms-and-conditions',
      pathType: 'inapp',
    },
    {
      label: 'Privacy Policy',
      url: 'info/privacy-policy',
      pathType: 'url',
    },
    { label: 'FAQs', url: 'info/faq', pathType: 'inapp' },
    { label: 'Contact Us', url: 'info/customer-support', pathType: 'url' },
  ],
};
