import { Component } from '@angular/core';

import { appConstants } from '../app.constants';
import { FooterItem } from '../hdfc.types';

@Component({
  selector: 'hdfc-si-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
  providers: [],
})
export class FooterComponent {
  public footerItems: FooterItem[] = appConstants.footerItems;
  public routerNavigate(item: FooterItem): void {
    if (typeof item.url === 'string') {
      window.open(item.url, '_blank');
    }
  }
}
