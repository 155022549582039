<footer
  class="md:flex w-screen footer justify-content-center align-items-center flex-column text-sm">
  <div
    class="w-full flex justify-content-between flex-column md:flex-row mb-6 md:pv-mb-24px">
    <div class="flex align-items-center justify-content-center mb-6 md:mb-0">
      <span class="width pv-mr-12px">
        <img
          alt="hdfcbank-logo"
          src="https://images.poshvine.com/hdfc-si/HDFC-only-symbol-logo.png" />
      </span>
      <span class="font-bold">&#169;2024, Powered by Razorpay</span>
    </div>
    <div
      class="items-container flex justify-content-center align-items-center gap-3">
      <ng-container *ngFor="let item of footerItems; let i = index">
        <a
          [href]="item.url"
          class="no-underline"
          target="_blank"
          (click)="routerNavigate(item)">
          {{ item.label }}
        </a>
      </ng-container>
    </div>
  </div>

  <div
    class="flex justify-content-between align-items-center flex-column md:flex-row">
    <div class="disclaimer mb-4 md:mb-0 text-xs">
      <div>
        Disclaimer: HDFC Bank Diners Club Offers & Privileges is a platform for
        communication of personalized offers extended by Merchants to HDFC
        Bank's Customers. HDFC Bank is only communicating the offers extended by
        Merchants to its Customers and not Selling/Rendering any of these
        Products/Services. HDFC Bank is neither guaranteeing nor making any
        representation. HDFC Bank is not responsible for Sale/Quality/Features
        of the Products/Services under the offers
      </div>
      <br />
      <div>
        Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and Edge at
        1024 x 768 pixels resolution, or above.
      </div>
    </div>
    <img
      alt="pci-logo"
      class="w-3rem"
      src="https://images.poshvine.com/logos/pci-logo.png" />
  </div>
</footer>
