import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserCardsResponse } from '@pv-frontend/authentication';
import {
  GvEnvironment,
  UserCardPointsResponse,
} from '@pv-frontend/gift-voucher-journey';
import { BenefitAttributes } from '@pv-frontend/gift-voucher-journey/interfaces/benefit-response';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Observable, Subject } from 'rxjs';

import { AllBenefitsResponse, BundleRedemptionResponse } from './hdfc.types';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  public reloadHeaderEvent: Subject<void> = new Subject<void>();
  public constructor(
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,
    private http: HttpClient,
    @Inject('environment')
    public environment: GvEnvironment & { bundleCampaignId: string }
  ) {}

  public getAllBenefits(
    params: Record<string, string | number>
  ): Observable<AllBenefitsResponse> {
    return this.http.get<{
      data: {
        id: string;
        attributes: BenefitAttributes;
        type: 'benefit';
      }[];
      meta: Record<string, string>;
    }>(`${this.environment.apiPerksServiceUrl}/benefits`, {
      params: params,
    });
  }

  public getUserCards(): Observable<UserCardsResponse> {
    return this.http.get<UserCardsResponse>(
      `${this.environment.apiClientsServiceUrl}/user/user_cards`
    );
  }

  public getUserPoints(): Observable<UserCardPointsResponse> {
    return this.http.get<UserCardPointsResponse>(
      `${this.environment.apiClientsServiceUrl}/user/points?fetch_points=true`
    );
  }

  public getCampaignData(
    params?: Record<string, string>
  ): Observable<BundleRedemptionResponse> {
    return this.http.get<BundleRedemptionResponse>(
      `${this.environment.campaignPath}/campaigns/${this.environment.bundleCampaignId}/bundle_redemption_options`,
      {
        params: params,
      }
    );
  }
}
