import { Component, EventEmitter, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Subject, filter, takeUntil } from 'rxjs';

import { APIService } from './api.service';

@Component({
  selector: 'hdfc-si-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private destroy$: Subject<void> = new Subject<void>();
  public isLoggedIn: boolean;
  public isHeaderVisible = true;
  public isFooterVisible = true;
  public reloadHeaderEvent: Subject<void> = new EventEmitter<void>();
  public searchVisible: boolean = false;
  public setOfHeaderInvisibilityPaths = new Set([
    'search',
    'order-status',
    'giftcards',
    'memberships',
    'booking',
    'order-history',
    'order-details',
    'order-history?pageType=GiftCardBooking',
    'order-history?pageType=MembershipBooking',
    'order-history?pageType=BundleBooking',
    'order-history?pageType=OfferBooking',
    'order-history?pageType=bundle',
  ]);
  public setOfFooterInvisibilityPaths = new Set(['giftcards', 'memberships']);
  public constructor(
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserService,
    private router: Router,
    public apiService: APIService
  ) {
    this.isLoggedIn = this.pvUserService.isUserAuthenticated();
    this.pvUserService.userLoggedInObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      });
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe((res: NavigationEnd) => {
        this.isFooterVisible = !this.setOfFooterInvisibilityPaths.has(
          res.url.split('/')?.[1] ?? ''
        );
        if (res.url === '/login' && this.pvUserService.isUserAuthenticated()) {
          this.router.navigate(['']);
        }
        this.searchVisible = res.url !== '/login';
        if (!this.pvUserService.isUserAuthenticated()) {
          this.router.navigate(['login']);
          return;
        }
        /**
         * Checks for paths where we have to stop showing the header in our app,
         * This is only a temp fix as we will have to fix the root cause in the journey app
         * by sepearating the headers config and removing this extra header from the above pages from
         * the journey itself
         */
        this.isHeaderVisible = !this.setOfHeaderInvisibilityPaths.has(
          res.url.split('/')?.[1] ?? ''
        );
      });

    /**
     * This subscription , when triggered, will reload the header helping the internal header state
     * to reset to its original one. This caters to the specific case of post login card check where the user is
     * logged out via logoutUser() user function, which navigates the user back to the login page, although the
     * local storage gets cleared and the user is technically logged out, header still shows invalid info as its
     * state is maintained internally.
     * The setTimeout below takes care of this as the header is essentially reloaded due to the visible flag being
     * toggled, forcing it to take its state anew from the localstorage and hence being cleared.
     */
    this.apiService.reloadHeaderEvent
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isHeaderVisible = false;
        setTimeout(() => {
          this.isHeaderVisible = true;
        }, 200);
      });
  }
}
