import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PvAuthenticationModule } from '@pv-frontend/authentication';
import {
  GvBundleDetailsModule,
  GvRedemptionModule,
} from '@pv-frontend/gift-voucher-journey';
import { PvButtonModule } from '@pv-frontend/pv-shared-components/button';
import { PvCardModule } from '@pv-frontend/pv-shared-components/card';
import { PvChipModule } from '@pv-frontend/pv-shared-components/chip';
import { PvDrawerModule } from '@pv-frontend/pv-shared-components/drawer';
import { SvgIconModule } from '@pv-frontend/pv-shared-components/svg-icon';
import { CarouselModule } from 'primeng/carousel';
import { SkeletonModule } from 'primeng/skeleton';

import { BenefitsCarouselComponent } from './benefits-carousel/benefits-carousel.component';
import { BenefitsExplainerComponent } from './benefits-explainer/benefits-explainer.component';
import { HomeComponent } from './home.component';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    PvButtonModule,
    PvAuthenticationModule,
    GvRedemptionModule,
    CarouselModule,
    PvCardModule,
    PvChipModule,
    SkeletonModule,
    GvBundleDetailsModule,
    PvDrawerModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomeComponent,
      },
    ]),
  ],
  declarations: [
    HomeComponent,
    BenefitsExplainerComponent,
    BenefitsCarouselComponent,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
